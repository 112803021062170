import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    errorRead,
    doRequest
} from '../../../actions';
import Outcome from '../../elements/Outcome';
import IsProfileComplete from '../../../lib/IsProfileComplete';
import SchemaValidator from '../../../lib/SchemaValidator';
import User from '../../elements/Forms/User';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    errorRead: () => dispatch(errorRead()),
    enroll: payload => dispatch(doRequest('enroll', payload)),
});

const _Profile = ({ data, errorRead, enroll, stateError, user }) => {

    const profileComplete = IsProfileComplete(user);
    const decodedGeodata = user.geodata ? JSON.parse(user.geodata) : null;
    const address = decodedGeodata && decodedGeodata[0] != null ? decodedGeodata[0].formatted_address : '';
    const intl = useIntl();

    const initialPayload = {
        name: user.name || '',
        lastname: user.lastname || '',
        birth_date: user.birth_date || '',
        fiscal_code: user.fiscal_code || '',
        vat: user.vat || '',
        company: user.company || '',
        phone: user.phone || '',
        mobile: user.mobile || '',
        fax: user.fax || '',
        email2: user.email2 || '',
        geodata: user.geodata || '',
        newsletter: user.newsletter || 0,
    };

    const [errors, setErrors] = useState("");
    const [processed, setProcessed] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const [success, setSuccess] = useState(false);

    useEffect(
        () => {
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError]
    );

    const handlerChange = (e) => {
        let _payload = payload;
        if (e instanceof Date) {
            _payload.birth_date = new Date(e.getTime() - (e.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        } else if (e.target.type === 'checkbox') {
            _payload[e.target.name] = e.target.checked ? 1 : 0;
        } else {
            _payload[e.target.name] = e.target.value;
        }
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerGeodata = results => {
        let _payload = payload;
        _payload.geodata = JSON.stringify(results);
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerSubmit = (e) => {
        e.preventDefault();
        setErrors("");
        setSuccess(false);
        let v = SchemaValidator(payload, 'enroll');
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => {
                let x = el.instancePath.replace(/^\/+/, '')
                return !!v.schema.properties[x].message === true ? v.schema.properties[x].message : null;
            }).filter(el => !!el).join("\n"));
        } else {
            setProcessed(true);
            enroll(payload);
        }
    }

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        setSuccess(true);
        if (!profileComplete) {
            setTimeout(() => window.location.reload(), 2000);
        }
    }

    return (
        <section>
            <div className="innerSection">
                <h3><FormattedMessage id="App.info.Profilo" /></h3>
                <br />
                {
                    !profileComplete
                    && (
                        <div className="rack">
                            <blockquote>
                                <FormattedMessage id="App.info.CompletaProfilo" />
                            </blockquote>
                        </div>
                    )
                }
                {
                    success
                        ? (
                            <Outcome text={intl.formatMessage({ id: "App.info.ProfiloSalvato" })} type="ok" onClose={setSuccess} />
                        )
                        : !!errors && errors.length
                            ? <Outcome text={errors} type="ko" onClose={setErrors} />
                            : <></>
                }
                <User
                    address={address}
                    user={user}
                    handleChange={handlerChange}
                    handleSubmit={handlerSubmit}
                    handlerGeodata={handlerGeodata}
                    picture={true}
                />
            </div>
        </section>
    );
};

const Profile = connect(mapStateToProps, mapDispatchToProps)(_Profile);

export default Profile;