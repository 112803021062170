import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    errorRead,
    doRequest,
    getProfile,
} from '../../../actions';

import Modal from '../../elements/Modal';
import Outcome from '../../elements/Outcome';
import { clone } from '../../../lib/clone';
import { roles, states } from '../../../constants';
import { checkStatoImpianto, checkZoneAperte } from '../../../lib/goldParser/stateParser';
import { getAllSystem } from '../../../api/Cloud';


const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
});

const mapDispatchToProps = (dispatch) => ({
    deleteRole: id => dispatch(doRequest('deleteRole', id)),
    deleteSystem: id => dispatch(doRequest('deleteSystem', id)),
    errorRead: () => dispatch(errorRead()),
    getProfile: () => dispatch(getProfile())
});

const _Home = ({ data, stateError, deleteRole, deleteSystem, errorRead, getProfile }) => {

    const intl = useIntl();
    const leds = [
        intl.formatMessage({ id: "App.info.On" }),
        intl.formatMessage({ id: "App.info.Off" }),
        intl.formatMessage({ id: "App.info.Standby" }),
        intl.formatMessage({ id: "App.info.nil" }),
    ];

    const [confirm, setConfirm] = useState(false);
    const [confirm2, setConfirm2] = useState(false);
    const [errors, setErrors] = useState("");
    const [firstTab, setFirstTab] = useState(false);
    const [processed2, setProcessed2] = useState(false);
    const [systems, setSystems] = useState(false);
    const [systemId, setSystemId] = useState(false);
    const [filters, setFilters] = useState(
        {
            name: null,
            address: null,
            connesso: null,
            profiles: null,
            state: null
        }
    );

    useEffect(() => {
        pollSystem();
        const interval = setInterval(async () => {
            await pollSystem();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(
        () => {
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [stateError]
    );

    const tabHandler = value => setFirstTab(value);

    const handleChangeFilter = (e) => {
        let _filters = filters;
        _filters[e.target.name] = e.target.value.length ? e.target.value : null;
        setFilters({
            ...filters,
            ..._filters,
        });
    }

    const closeModal = e => {
        e.preventDefault();
        setConfirm(false);
        setConfirm2(false);
        setSystemId(false);
    }

    const confirmDelete = e => {
        e.preventDefault();
        setProcessed2(true);
        deleteSystem(systemId);
        let _systems = clone(systems);
        let idx = _systems.findIndex(x => x.id === systemId);
        if (idx != null) {
            delete _systems[idx];
        }
        setSystems([..._systems.filter(x => !!x)]);
    }

    const confirmDeleteRole = e => {
        e.preventDefault();
        setProcessed2(true);
        deleteRole(systemId);
    }

    const renderMap = () => {
        const markers = [];
        if (systems && systems.length > 0) {
            for (let i in systems) {
                let geodata = !!systems[i].geodata ? systems[i].geodata : null;

                try {
                    geodata = JSON.parse(systems[i].geodata);
                } catch (err) {
                    geodata = null;
                }

                markers.push({
                    latitude: geodata ? parseFloat(geodata[0].geometry.location.lat) : 0,
                    longitude: geodata ? parseFloat(geodata[0].geometry.location.lng) : 0,
                    address: geodata ? geodata[0].formatted_address : 'n.d.',
                    ...systems[i]
                });
            }
            return (
                <div className="rack">
                    <div className="w100 box">
                        <em><FormattedMessage id="App.info.NoMap" /></em>
                    </div>
                </div>
            );
        }
        return (
            <div className="rack">
                <div className="w100 box">
                    <p>
                        <FormattedMessage id="App.info.NessunImpiantoCensito" />
                    </p>
                </div>
            </div>
        );
    };

    const renderList = () => (
        <div className="rack">
            <div className="w100">
                <form>
                    <fieldset>
                        <table className="responsiveTable">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th><FormattedMessage id="App.info.Nome" /></th>
                                    <th><FormattedMessage id="App.info.Indirizzo" /></th>
                                    <th><FormattedMessage id="App.info.Ruolo" /></th>
                                    <th><FormattedMessage id="App.info.AccountSiaIp" /></th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                    <th><FormattedMessage id="App.info.Azioni" /></th>
                                </tr>
                                <tr className="filters">
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        <span className="head"><FormattedMessage id="App.info.Nome" /></span>
                                        <input type="text" placeholder={intl.formatMessage({ id: "App.info.Cerca" })} name="name" onChange={handleChangeFilter} />
                                    </td>
                                    <td>
                                        <span className="head"><FormattedMessage id="App.info.Indirizzo" /></span>
                                        <input type="text" placeholder={intl.formatMessage({ id: "App.info.Cerca" })} name="address" onChange={handleChangeFilter} />
                                    </td>
                                    <td>
                                        <span className="head"><FormattedMessage id="App.info.Ruolo" /></span>
                                        <select defaultValue="" name="profiles" onChange={handleChangeFilter}>
                                            <option value=""></option>
                                            {
                                                roles.map((value, index) => <option key={index} value={value}>{value}</option>)
                                            }
                                        </select>
                                    </td>
                                    <td>
                                        <span className="head">
                                            <FormattedMessage id="App.info.AccountSiaIp" />
                                        </span>
                                        <input type="text" placeholder={intl.formatMessage({ id: "App.info.Cerca" })} name="account_sia" onChange={handleChangeFilter} />
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    systems && (
                                        systems.map((value, index) => {
                                            let filtered = false;
                                            let decodedGeodata = null;
                                            try {
                                                decodedGeodata = JSON.parse(value.geodata)
                                            } catch (err) {
                                            }
                                            value.address = decodedGeodata && decodedGeodata[0] != null ? decodedGeodata[0].formatted_address : '';
                                            for (let j in filters) {
                                                if (filters[j] != null) {
                                                    filtered = j === 'connesso'
                                                        ? value[j] != filters[j]
                                                        : !value[j].toLowerCase().includes(filters[j].toLowerCase())
                                                        ;
                                                    if (filtered) {
                                                        break;
                                                    }
                                                }
                                            }
                                            let systemImage = value.pic || `${value.modello}.jpg`
                                                ? <img style={{ float: 'center', width: '96px' }} alt={intl.formatMessage({ id: "App.info.FotoImpianto" })} src={(value.pic ? `${process.env.REACT_APP_API_URL}/download/${value.pic}` : `/static/images/model/${value.modello}.png`)} />
                                                : <></>
                                                ;
                                            return filtered
                                                ? <tr key={index} style={{ border: 0 }}></tr>
                                                : (
                                                    <tr key={index} style={{ textAlign: "center", minHeight: "3em" }}>
                                                        <td style={{ textAlign: "center" }}>
                                                            {systemImage}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}><strong>{value.name}</strong><br /><br /><strong>{value.owner}</strong><br /><small>
                                                            <FormattedMessage id="App.info.Proprietario_" />
                                                        </small></td>
                                                        <td className="tdw27" style={{ textAlign: "center" }}>{value.address}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <span className="head" style={{ textAlign: "center" }}>
                                                                <FormattedMessage id="App.info.Ruolo_" />
                                                            </span>
                                                            {(value.profiles).split(',').map((x, idx) => <Fragment key={idx}>{x}<br /></Fragment>)}
                                                        </td>

                                                        <td class="soloMobile" style={{ textAlign: 'center' }}><strong><br />
                                                            <FormattedMessage id="App.info.SiaIp_" />
                                                        </strong></td>
                                                        <td style={{ textAlign: "center" }}>{value.account_sia}</td>
                                                        {!!value.goldstate
                                                            ? (
                                                                <>
                                                                    <td className="tdw25">
                                                                        <i className="fa fa-cloud" title={value.connesso ? intl.formatMessage({ id: "App.info.Connesso" }) : intl.formatMessage({ id: "App.info.NonConnesso" })} style={{ color: (value.connesso ? 'rgb(40, 170, 40)' : 'red') }} />
                                                                    </td>
                                                                    <td class="tdw25">
                                                                        <i className={`fa ${(value.goldstate.gprs === 2 ? `fa-signal` : `fa-wifi`)}`} title={value.connesso ? `${intl.formatMessage({ id: "App.info.Connesso" })} ${(value.goldstate.gprs === 2 ? intl.formatMessage({ id: "App.info.Gprs" }) : intl.formatMessage({ id: "App.info.Wifi" }))}` : intl.formatMessage({ id: "App.info.NonConnesso" })} style={{ color: (value.connesso ? 'rgb(40, 170, 40)' : 'red') }} />
                                                                    </td>
                                                                    <td className="tdw25"><i className={`fa fa-circle`} style={{ color: ((checkStatoImpianto(value.goldstate) || !!checkZoneAperte(value.goldstate)[0]) ? 'rgb(255, 194, 10)' : 'rgb(40, 170, 40)') }} title={intl.formatMessage({ id: "App.info.StatoImpianto" })} /></td>
                                                                    <td className="tdw25">{!!(value.goldstate.prog & 0x80)
                                                                        ? <img src={`/static/images/icons/auth.png`} className="icon xs-icon" title={intl.formatMessage({ id: "App.info.StatoAutenticazione" })} />
                                                                        : <>&nbsp;</>
                                                                    }</td>
                                                                    <td className="tdw25">{value.profiles.includes('proprietario')
                                                                        ? !!(value.goldstate.prog & 0x07)
                                                                            ? <i className={`fa fa-lock`} style={{ color: 'red' }} title={intl.formatMessage({ id: "App.info.ImpiantoInserito" })} />
                                                                            : <i className={`fa fa-unlock-alt`} style={{ color: 'rgb(40, 170, 40)' }} title={intl.formatMessage({ id: "App.info.ImpiantoDisinserito" })} />
                                                                        : <>&nbsp;</>
                                                                    }</td>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <td className="tdw25"><i className="fa fa-cloud" title={value.connesso ? intl.formatMessage({ id: "App.info.Connesso" }) : intl.formatMessage({ id: "App.info.NonConnesso" })} style={{ color: (value.connesso ? 'rgb(40, 170, 40)' : 'red') }} /></td>
                                                                    <td className="tdw25">&nbsp;</td>
                                                                    <td className="tdw25">&nbsp;</td>
                                                                    <td className="tdw25">&nbsp;</td>
                                                                </>
                                                            )
                                                        }
                                                        <td className="tdw25">{!!value.state
                                                            ? <img src={`/static/images/icons/${value.state}.png`} className="icon xs-icon" title={value.state} />
                                                            : <>&nbsp;</>
                                                        }</td>
                                                        {
                                                            value.valid === 1
                                                                ? (
                                                                    <td style={{ textAlign: "center" }}>
                                                                        <span className="head">
                                                                            <FormattedMessage id="App.info.Azioni" />
                                                                        </span>
                                                                        <Link title={intl.formatMessage({ id: "App.info.PannelloDiControllo" })} onClick={e => { if (!value.connesso) { e.preventDefault(); } }} to={`/${(value.brand === 'lince-europlus' ? intl.formatMessage({ id: "App.info.Europlus" }) : intl.formatMessage({ id: "App.info.Gold" }))}/${value.id}`}>
                                                                            <img src='/static/images/icons/control.png' className="icon xs-icon" style={{ opacity: (value.connesso ? '1' : '.3') }} />
                                                                        </Link>
                                                                        {
                                                                            (value.profiles.includes('proprietario') || value.profiles.includes('manutentore')) && (
                                                                                <Link title={intl.formatMessage({ id: "App.info.ModificaImpianto" })} to={`/edit-system/${value.id}`}>
                                                                                    <i className="fa fa-pencil" />
                                                                                </Link>
                                                                            )
                                                                        }
                                                                        {
                                                                            (value.profiles.includes('proprietario'))
                                                                                ? (
                                                                                    <Link title={intl.formatMessage({ id: "App.info.EliminaImpianto" })} to={`/`} onClick={(e) => { e.preventDefault(); setSystemId(value.id); setConfirm(true); }} >
                                                                                        <i className="fa fa-trash" />
                                                                                    </Link>
                                                                                ) : (
                                                                                    <Link title={intl.formatMessage({ id: "App.info.EliminaRuolo" })} to={`/`} onClick={(e) => { e.preventDefault(); setSystemId(value.id); setConfirm2(true); }} >
                                                                                        <i className="fa fa-user-times" />
                                                                                    </Link>
                                                                                )
                                                                        }
                                                                    </td>
                                                                ) : (
                                                                    <td>
                                                                        <em><small>
                                                                            <FormattedMessage id="App.info.AccountPremiumRichiesto" />
                                                                        </small></em>
                                                                    </td>
                                                                )
                                                        }
                                                    </tr>
                                                )
                                        })
                                    )
                                }
                            </tbody>
                        </table>
                    </fieldset>
                </form>

            </div>
        </div>
    );

    const pollSystem = async () => {
        const [__data, __code] = await getAllSystem();
        if (__code === 200) {
            setSystems(__data);
        }
    };

    if (data && processed2) {
        setProcessed2(false);
        setSystems(false);
        setSystemId(false);
        setConfirm(false);
        setConfirm2(false);
        setTimeout(() => getProfile(), 500);
    }

    return (
        <section>
            <div className="innerSection">
                <h3>
                    <FormattedMessage id="App.info.SezioneImpianti" />
                </h3>
                {
                    !!errors && errors.length > 0 && <Outcome text={errors} type="ko" onClose={setErrors} />
                }
                {
                    confirm && (
                        <>
                            <Modal
                                title={intl.formatMessage({ id: "App.info.CancellazioneImpianto" })}
                                text={intl.formatMessage({ id: "App.info.ConfermareEliminazioneDefinitivaImpianto" })}
                                onClose={closeModal}
                                onConfirm={confirmDelete}
                            />
                        </>
                    )
                }
                {
                    confirm2 && (
                        <>
                            <Modal
                                title={intl.formatMessage({ id: "App.info.CancellazioneRuolo" })}
                                text={intl.formatMessage({ id: "App.info.ConfermareEliminazioneDefinitivaRuolo" })}
                                onClose={closeModal}
                                onConfirm={confirmDeleteRole}
                            />
                        </>
                    )
                }
                <div className="rack box">
                    <div className="w50">
                        <h2 className={`tab tastiImpianti ${(firstTab ? 'active' : 'disabled')}`} style={{ borderBottomLeftRadius: '1em', borderTopLeftRadius: '1em', borderBottomRightRadius: '0em', borderTopRightRadius: '0em' }} onClick={() => tabHandler(true)}>
                            <FormattedMessage id="App.info.MappaImpianti" />
                        </h2>
                    </div>
                    <div className="w50">
                        <h2 className={`tab tastiImpianti ${(firstTab ? 'disabled' : 'active')}`} style={{ borderBottomLeftRadius: '0em', borderTopLeftRadius: '0em', borderBottomRightRadius: '1em', borderTopRightRadius: '1em' }} onClick={() => tabHandler(false)}>
                            <FormattedMessage id="App.info.ListaImpianti" />
                        </h2>
                    </div>
                </div>
                {
                    firstTab
                        ? renderMap()
                        : renderList()
                }
            </div>
        </section>
    );
};

const Home = connect(mapStateToProps, mapDispatchToProps)(_Home);

export default Home;