import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
    errorRead,
    doRequest,
    logout
} from '../../../actions';
import Error from '../../elements/Error';
import Outcome from '../../elements/Outcome';
import SchemaValidator from '../../../lib/SchemaValidator';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    errorRead: () => dispatch(errorRead()),
    doRequestEmailChange: payload => dispatch(doRequest('emailChange', payload)),
    doRequestConfirmEmailChange: payload => dispatch(doRequest('emailChangeConfirm', payload)),
    doRequestDismissEmailChange: () => dispatch(doRequest('emailChangeDismiss')),
    doRequestPasswordChange: payload => dispatch(doRequest('passwordChange', payload)),
    logout: () => dispatch(logout())
});

const _Settings = ({ data, logout, errorRead, doRequestEmailChange, doRequestConfirmEmailChange, doRequestDismissEmailChange, doRequestPasswordChange, stateError, user }) => {
    const initialPayload = {
        new_email: "",
        confirm_email: "",
        password: "",
        new_password: "",
        confirm_password: "",
        code: "",
    };

    const [errors, setErrors] = useState("");
    const [firstTab, setFirstTab] = useState(true);
    const [localError, setLocalError] = useState("");
    const [processed1, setProcessed1] = useState(false);
    const [processed2, setProcessed2] = useState(false);
    const [processed3, setProcessed3] = useState(false);
    const [processedPassword, setProcessedPassword] = useState(false);
    const [success1, setSuccess1] = useState(!!user.change_email);
    const [successPassword, setSuccessPassword] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const codeField = useRef(null);
    const firstField = useRef(null);
    const secondField = useRef(null);
    const thirdField = useRef(null);
    const intl = useIntl();

    useEffect(
        () => {
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError]
    );

    useEffect(
        () => {
            if (success1) {
                codeField.current.value = '';
            }
            return () => null;
        },
        [success1]
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const isDisabled = () => !(payload.password.length > 0 && payload.new_email.length > 0 && payload.confirm_email.length > 0);
    const isDisabled2 = () => !(payload.password.length > 0 && payload.new_password.length > 0 && payload.confirm_password.length > 0);
    const isDisabled3 = () => !(payload.code.length > 0);

    const tabHandler = value => {
        firstField.current.value = '';
        secondField.current.value = '';
        thirdField.current.value = '';
        setFirstTab(value);
    }

    const handleChangeEmail = (e) => {
        e.preventDefault();
        const _payload = {
            new_email: payload.new_email,
            password: payload.password,
        }
        if (payload.new_email !== payload.confirm_email) {
            setLocalError(intl.formatMessage({ id: "App.info.IndirizziEmailNonCoincidono" }));
        } else {
            setLocalError("");
            let v = SchemaValidator(_payload, 'emailchange');
            if (v.errors.length > 0) {
                setErrors(v.errors.map(el => {
                    let x = el.instancePath.replace(/^\/+/, '')
                    return !!v.schema.properties[x].message === true ? v.schema.properties[x].message : null;
                }).filter(el => !!el).join("\n"));
            } else {
                setLocalError("");
                setProcessed1(true);
                doRequestEmailChange(_payload);
            }
        }
    }

    const handleChangePassword = (e) => {
        e.preventDefault();
        const _payload = {
            password: payload.new_password,
            password_old: payload.password,
        }
        if (payload.new_password !== payload.confirm_password) {
            setLocalError(intl.formatMessage({ id: "App.info.LeDuePasswordNonCoincidono" }));
        } else {
            setLocalError("");
            let v = SchemaValidator(_payload, 'passwordchange');
            if (v.errors.length > 0) {
                setErrors(v.errors.map(el => {
                    let x = el.instancePath.replace(/^\/+/, '')
                    return !!v.schema.properties[x].message === true ? v.schema.properties[x].message : null;
                }).filter(el => !!el).join("\n"));
            } else {
                setLocalError("");
                setProcessedPassword(true);
                doRequestPasswordChange(_payload);
            }
        }
    }

    const handleConfirmChangeEmail = (e) => {
        e.preventDefault();
        const _payload = {
            code: payload.code,
        }
        setProcessed2(true);
        doRequestConfirmEmailChange(_payload);
    }

    const handleDismissChangeEmail = (e) => {
        e.preventDefault();
        setProcessed3(true);
        doRequestDismissEmailChange();
    }

    if (data && processed1) {
        setErrors("");
        setProcessed1(false);
        setSuccess1(true);
    }

    if (data && processed2) {
        setErrors("");
        setProcessed2(false);
        setSuccess1(false);
        setTimeout(() => { logout() }, 3000);
    }

    if (data && processed3) {
        setErrors("");
        setProcessed3(false);
        setSuccess1(false);
    }

    if (data && processedPassword) {
        setErrors("");
        setProcessedPassword(false);
        setSuccessPassword(true);
        setTimeout(() => { logout() }, 3000);
    }

    const renderEmailDefault = () => (
        <div className="rack">
            <div className="w50 box">
                <form className="nopad left" id="form1">
                    <fieldset>
                        <p>
                            <label>
                                <FormattedMessage id="App.info.NuovaEmail" />
                            </label>
                            <input ref={firstField} type="text" id="email_new" defaultValue="" placeholder={intl.formatMessage({ id: "App.info.Email" })} name="new_email" onChange={handleChange} />
                        </p>
                        <p>
                            <label>
                                <FormattedMessage id="App.info.ConfermaEmail" />
                            </label>
                            <input ref={secondField} type="text" placeholder={intl.formatMessage({ id: "App.info.ConfermaEmail" })} name="confirm_email" onChange={handleChange} />
                        </p>
                        <p>
                            <label>
                                <FormattedMessage id="App.info.PasswordAttuale" />
                            </label>
                            <input ref={thirdField} type="password" placeholder={intl.formatMessage({ id: "App.info.PasswordAttuale" })} name="password" onChange={handleChange} />
                        </p>
                        <Error text={localError} />
                        <Error text={errors} />
                        <p>
                            <button disabled={isDisabled()} className="ok" onClick={handleChangeEmail}>
                                <FormattedMessage id="App.info.Conferma" />
                            </button>
                        </p>
                    </fieldset>
                </form>
            </div>
            <div className="w50 box">
                <p>
                    <FormattedMessage id="App.info.PerEffettuareCambioEmail" />
                </p>
                <p>
                    <FormattedMessage id="App.info.DopoModifica" />
                </p>
            </div>
        </div>
    );

    const renderEmailConfirm = () => (
        <div className="rack">
            <div className="w50 box">
                <form className="nopad left" id="form2" autocomplete="off">
                    <fieldset>
                        <p>
                            <label>
                                <FormattedMessage id="App.info.CodiceConfermaEmail" />
                            </label>
                            <input autoComplete="off" ref={codeField} id="email_code" type="text" placeholder={intl.formatMessage({ id: "App.info.Codice" })} name="code" onChange={handleChange} defaultValue="" />
                        </p>
                        <Error text={localError} />
                        <p>
                            <button disabled={isDisabled3()} className="ok" onClick={handleConfirmChangeEmail}>
                                <FormattedMessage id="App.info.Conferma" />
                            </button>
                        </p>
                    </fieldset>
                </form>
            </div>
            <div className="w50 box">
                <p>
                    <FormattedMessage id="App.info.ConfermaCambioEmail" />
                </p>
                <p>
                    <FormattedMessage id="App.info.SeCodiceCorretto" />
                </p>
                <p>
                    <FormattedMessage id="App.info.SeInveceVuoiAnnullare" />
                </p>
                <form className="nopad left">
                    <fieldset>
                        <p>
                            <button className="ok" onClick={handleDismissChangeEmail}>
                                <FormattedMessage id="App.info.AnnullaCambioEmail" />
                            </button>
                        </p>
                    </fieldset>
                </form>
            </div>
        </div>
    );

    const renderPasswordDefault = () => (
        <div className="rack">
            <div className="w50 box">
                <form className="nopad left">
                    <fieldset>
                        <p>
                            <label>
                                <FormattedMessage id="App.info.PasswordAttuale" />
                            </label>
                            <input ref={firstField} type="password" placeholder={intl.formatMessage({ id: "App.info.PasswordAttuale" })} name="password" onChange={handleChange} />
                        </p>
                        <p>
                            <label>
                                <FormattedMessage id="App.info.InserisciNuovaPassword" />
                            </label>
                            <input ref={secondField} type="password" placeholder={intl.formatMessage({ id: "App.info.NuovaPassword" })} name="new_password" onChange={handleChange} />
                        </p>
                        <p>
                            <label>
                                <FormattedMessage id="App.info.ConfermaPassword" />
                            </label>
                            <input ref={thirdField} type="password" placeholder={intl.formatMessage({ id: "App.info.ConfermaPassword" })} name="confirm_password" onChange={handleChange} />
                        </p>
                        <Error text={errors} />
                        <Error text={localError} />
                        <p>
                            <button disabled={isDisabled2()} className="ok" onClick={handleChangePassword}>
                                <FormattedMessage id="App.info.Conferma" />
                            </button>
                        </p>
                    </fieldset>
                </form>
            </div>
            <div className="w50 box">
                <p>
                    <FormattedMessage id="App.info.PerEffettuareCambioPassword1" />
                </p>
                <p>
                    <FormattedMessage id="App.info.PerEffettuareCambioPassword2" />
                    <br />
                    <FormattedMessage id="App.info.PerEffettuareCambioPassword3" />
                    <br />
                    <FormattedMessage id="App.info.PerEffettuareCambioPassword4" />
                </p>
                <p>
                    <FormattedMessage id="App.info.DopoAverEffettuatoModifica" />
                </p>
            </div>
        </div>
    );

    return (
        <section>
            <div className="innerSection">
                <h3>
                    <FormattedMessage id="App.info.Impostazioni" />
                </h3>
                <div className="rack box">
                    {
                        successPassword && (
                            <Outcome text={intl.formatMessage({ id: "App.info.PasswordCambiataDisconnessione" })} type="ok" />
                        )
                    }
                    <div className="w50">
                        <h2 className={`tab ${(firstTab ? 'active' : 'disabled')}`} onClick={() => tabHandler(true)}>
                            <FormattedMessage id="App.info.CambioEmail" />
                        </h2>
                    </div>
                    <div className="w50">
                        <h2 className={`tab ${(firstTab ? 'disabled' : 'active')}`} onClick={() => tabHandler(false)}>
                            <FormattedMessage id="App.info.CambioPassword" />
                        </h2>
                    </div>
                </div>
                {
                    firstTab
                        ? success1
                            ? renderEmailConfirm()
                            : renderEmailDefault()
                        : renderPasswordDefault()
                }
            </div>
        </section>
    );
};

const Settings = connect(mapStateToProps, mapDispatchToProps)(_Settings);

export default Settings;