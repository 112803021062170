const SchemaValidator = (data, schema) => {
    const Ajv = require("ajv");
    const addFormats = require("ajv-formats");
    const ajv = new Ajv();
    addFormats(ajv);
    ajv.addKeyword({
        keyword: "message",
        type: "string"
    });
    let json = require('../schemas/' + schema + '.json');
    const validate = ajv.compile(json);
    const valid = validate(data);
    let response = { errors: [], schema: validate.schema };
    if(!valid) {
        response.errors = validate.errors;
    }
    console.log(response)
    return response;
};

export default SchemaValidator;